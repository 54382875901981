import React from 'react'
import { Button } from 'antd'

function Footer () {
  return (
    <div className='footer dark-background'>
      <Button type='link' ghost>somethin something copyright something</Button>
    </div>
  )
}

export default Footer
